@import "../styles/animate.css";



_::-webkit-full-page-media, _:future, :root .safari_only {
    
  }


@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) {
   
        .safari6 {
            color:#0000FF;
            background-color:#CCCCCC;
        }
    
  }

#page-container {
  position: relative;
  min-height: 100vh;
}

.fadein {
  opacity:1;
  transition: 1s ease;
  }
  
.fadein:hover{
  opacity:0.7;
  transition: 1s ease;
  }

.zoomout {
  transition: transform .2s ease-in; /* Animation */
  
  }
  
.zoomout:hover{
  transform: scale(1.05);
  transition: .2s ease-in-out;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
        transform: scale(1,1);
    }
    50% {
      transform: scale(1.05,1.05);
  }
    100% {
        transform: scale(1.05,1.05);
    }
}

.zoomin:hover{
	animation: zoominoutsinglefeatured 1.2s ease ;
}

.zoomease {
  transition: 0.8s ease;
  }
  
.zoomease:hover{
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  transition: 0.8s ease;
  }


@keyframes zoomoutins {
  0% {
      transform: scale(1,1);
  }
  100% {
      transform: scale(0.6,0.6);
      transform: rotate(2deg);
  }
}
.zoomoutin:hover{
  animation: zoomoutins 0.6s ease-in-out ;
}


 


   

.header-input[type="text"] {
  background: transparent;
  border: none;
  border-bottom: 1px solid #ffffff;
  width: 60px;
  color: #ffffff;
  outline: none;
  padding: 2px;
  transition: 0.3s;
  transition-timing-function: cubic-bezier(0,0,1,1);
}

.header-input[type="text"]:hover {
  width: 100px;
  border: none;
  border-bottom: 1px solid #ffffff;
  outline: none;
  padding: 2px;
  
  

}
.header-input[type="text"]:focus:hover {
  outline: none;

  border-radius: 0;
  width: 100px;
  
}

select {
  width: 132px;
  padding: 2px;
  border: none;
  outline: none;
  border-radius: 5px;
  text-align: center;

  -webkit-appearance: none;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff;
  opacity: 1; /* Firefox */
  font-size: 12px
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav {
  background-color: red;
  color: white;
}

.nav span {
  font-weight: bold;
}

@media only screen and (max-width: 576px) {
  .nw1-smaller-header {
    background: #000000;
    background: -webkit-linear-gradient(77deg, #A9D046, #14c170 100%);
    background: -moz-linear-gradient(77deg, #A9D046, #14c170 100%);
    background: linear-gradient(150deg, #A9D046, #14c170 100%);
    height: 17rem;
    width: 100vw;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-left: 0.8em;
    margin-top: -18px;
    overflow: hidden;
    
  }

  .nw1-bigger-header {
    background-color: #c1de76;
    height: 18rem;
    display: flex;
    flex-direction: column;
   padding-right: 30px;
    padding-bottom: 180px;
    padding-top: 180px;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .sell {
    font-size: 0.5em;
    border-radius: 5px;
    font-weight: bold;
    -webkit-animation-name: bounce; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 1s; /* Safari 4.0 - 8.0 */
    animation-name: bounce;
    animation-duration: 1s;
  }

  .wakanda-logo-div {
    position: relative;
  }

  .nw1-category-header {
    border-bottom-left-radius: 20px;
    background-color: #000000;
    overflow-x: hidden;
  }

  
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .nw1-smaller-header {
    background: #A9D046;
    background: -webkit-linear-gradient(77deg, #A9D046, #14c170 100%);
    background: -moz-linear-gradient(77deg, #A9D046, #14c170 100%);
    background: linear-gradient(150deg, #A9D046, #14c170 100%);
    height: 400px;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: white;
    border-bottom-left-radius: 65px;
    border-bottom-right-radius: 65px;
    top: 0;
    padding-left: 3.8em;
  }
  .nw1-bigger-header {
    background-color: #c1de76;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 100px;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    border-bottom-left-radius: 65px;
    border-bottom-right-radius: 65px;
    display: none;
  }

  .sell {
    font-size: 0.5em;
  }

  .wakanda-logo-div {
    position: relative;
  }

  .nw1-category-header {
    border-bottom-left-radius: 40px;
    background-color: #000000;
    overflow-x: hidden;
  }

  .cat-img {
    width: 30px;
    height: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .nw1-smaller-header {
    background: #A9D046;
    background: -webkit-linear-gradient(77deg, #A9D046, #14c170 100%);
    background: -moz-linear-gradient(77deg, #A9D046, #14c170 100%);
    background: linear-gradient(150deg, #A9D046, #14c170 100%);
    height: 300px;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: white;
    border-bottom-left-radius: 65px;
    border-bottom-right-radius: 65px;
    top: 0;
    padding-left: 3.8em;
  }
  .nw1-bigger-header {
    background-color: #c1de76;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 100px;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    border-bottom-left-radius: 65px;
    border-bottom-right-radius: 65px;
    display: none;
  }
  .sell {
    font-size: 0.4em;
  }

  .wakanda-logo-div {
    position: relative;
  }

  .nw1-category-header {
    border-bottom-left-radius: 40px;
    background-color: #000000;
    overflow-x: hidden;
  }
  .cat-img {
    width: 50px;
    height: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px){
  .nw1-smaller-header {
    background: #A9D046;
    background: -webkit-linear-gradient(77deg, #A9D046, #14c170 100%);
    background: -moz-linear-gradient(77deg, #A9D046, #14c170 100%);
    background: linear-gradient(150deg, #A9D046, #14c170 100%);
    min-height: 260px;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: white;
    border-bottom-left-radius: 65px;
    border-bottom-right-radius: 65px;
    top: 0;
    padding-left: 3.8em;
  }
  .nw1-bigger-header {
    background-color: #c1de76;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 100px;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    border-bottom-left-radius: 65px;
    border-bottom-right-radius: 65px;
    display: none;
  }
  .sell {
    font-size: 0.4em;
  }

  .wakanda-logo-div {
    position: relative;
  }

  .nw1-category-header {
    border-bottom-left-radius: 40px;
    background-color: #000000;
    overflow-x: hidden;
  }
  .cat-img {
    width: 50px;
    height: 50px;
  }
}

@media only screen and (min-width: 1200px) {
  .nw1-smaller-header {
    background: #c1de76;
    background: -webkit-linear-gradient(77deg, #c1de76, #14c170 100%);
    background: -moz-linear-gradient(77deg, #c1de76, #14c170 100%);
    background: linear-gradient(150deg, #c1de76, #14c170 100%);
    height: 260px;
    width: 75%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: white;
    border-bottom-left-radius: 65px;
    border-bottom-right-radius: 65px;
    position: absolute;
    top: 0;
    padding-left: 3.8em;
  }

  .nw1-bigger-header {
    background-color: #c1de76;
      height: 260px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-right: 100px;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
      border-bottom-left-radius: 65px;
      border-bottom-right-radius: 65px;
    
  }

  .sell {
    font-size: 0.4em;
  }
  .wakanda-logo-div {
    position: relative;
  }

  .nw1-category-header {
    border-bottom-left-radius: 40px;
    background-color: #000000;
    overflow-x: hidden;
  }
  .cat-img{
    width: 60px;
    height: 60px;
  }
}


  .fadeinshop {
    opacity:0.8;
    transition: 0.5s ease;
    }
    
  .fadeinshop:hover{
    opacity:1;
    transition: 0.5s ease;
    }

  
  @media only screen and (max-width: 576px) {
    .nw1-smaller-header {
      background: #000000;
      background: -webkit-linear-gradient(77deg, #c1de76, #14c170 100%);
      background: -moz-linear-gradient(77deg, #c1de76, #14c170 100%);
      background: linear-gradient(150deg, #c1de76, #14c170 100%);
      height: 40vh;
      width: 100vw;
      display: flex;
      flex-direction: column;
      font-size: calc(10px + 2vmin);
      color: white;
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
      padding-left: 0.8em;
      margin-top: -8px;
      overflow: hidden;
      
    }
  
    .nw1-bigger-header {
      background-color: #c1de76;
      height: 40vh;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-left: 500px;
      padding-bottom: 100px;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
    }
  
    .shops-sell {
      font-size: 0.6em;
    }
  
    .wakanda-logo-div {
      position: relative;
    }
  
    
  }
  
  @media only screen and (min-width: 576px) and (max-width: 768px) {
    
  
    .shops-sell {
      font-size: 0.5em;
    }
  
    .wakanda-logo-div {
      position: relative;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    
  }
  
  @media only screen and (min-width: 992px) {
    
  
    .sell {
      font-size: 0.4em;
    }
    .wakanda-logo-div {
      position: relative;
    }
  }
  